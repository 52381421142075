@import './pre-customizations.scss';

$icon-font-path: '~bootstrap-sass/assets/fonts/bootstrap/';
$fa-font-path : '~font-awesome/fonts';

@import 'bootstrap-sass/assets/stylesheets/_bootstrap';
@import 'font-awesome/scss/font-awesome';

@media (max-width: 992px) {
  .navbar-header {
      float: none;
  }
  .navbar-toggle {
      display: block;
  }
  .navbar-collapse {
      border-top: 1px solid transparent;
      box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);
  }
  .navbar-collapse.collapse {
      display: none!important;
  }
  .navbar-nav {
      float: none!important;
      margin: 7.5px -15px;
  }
  .navbar-nav>li {
      float: none;
  }
  .navbar-nav>li>a {
      padding-top: 10px;
      padding-bottom: 10px;
  }
  .navbar-text {
      float: none;
      margin: 15px 0;
  }
  /* since 3.1.0 */
  .navbar-collapse.collapse.in { 
      display: block!important;
  }
  .collapsing {
      overflow: hidden!important;
  }
}

.checkbox label {
  padding-left: 30px;
}

input[type='checkbox'] {
  top: 5px;
  height: 24px;
  width: 24px;
  box-shadow: none;
  margin-left: -30px !important;
}